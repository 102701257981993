import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [


{    label: 'Official Website',
    href: '/mainsite',
    icon: 'HomeIcon',
    showItemsOnMobile: false,
    external: true,
    items: [

    ],
  },

{    label: t('NFTs'),
    href: `/mint`,
    icon: 'NftIcon',
    showItemsOnMobile: false,

    items: [
    
    ],
  },

{    label: t('Swap'),
    href: `/swap`,
    icon: 'SwapIcon',
    showItemsOnMobile: false,

    items: [

    ],
  },
 {
    label: 'Free to Play',
    href: `/games`,
    icon: 'TrophyIcon',
    showItemsOnMobile: false,

    items: [{
	label: 'RacingStars',
        href: '/racingstars',    },
{
        label: 'Arrow Shooting Stars',
        href: '/arrowshootingstars',    },

    ],
  },

{
    label: 'Play to Earn',
    href: `/games`,
    icon: 'TrophyIcon',
    showItemsOnMobile: false,

    items: [{
        label: 'Star Warrior',
        href: '/starwarrior',    },
{
        label: 'Fighting Stars',
        href: '/fightingstars',    },
    ],
  },

{
    label: 'Team',
    href: `/team`,
    icon: 'TeamPlayerIcon',
    showItemsOnMobile: false,
items:[],
},
]

export default config
