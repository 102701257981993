import React, { useContext } from 'react'
import styled from 'styled-components'
import { Breadcrumbs, Heading, Text, Link, Button } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

const Wrapper = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.textSubtle};
  margin-top: 32px;
  margin-bottom: 24px;
  padding-bottom: 24px;
`

const Header: React.FC = (props) => {
  const { t } = useTranslation()


  return (
    <Wrapper>
      <Heading as="h1" scale="xxl" color="contrast" mb="8px" id="profile-setup-title">
        Stake and Earn GS1
      </Heading>
      <Heading color="silver" as="h2" scale="lg" mb="8px">
        Stake and Earn GS1
      </Heading>
      <Text color="silver" mb="8px">

Stake GS1 to earn rewards overtime. 




      </Text>




    </Wrapper>
  )
}

export default Header
