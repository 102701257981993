import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'mailto:info@nftgamingstars.com',
      },
      {
        label: t('Community'),
        href: 'https://t.me/nftgamingGlobal',
      },
      {
        label: t('Whitepaper'),
        href: 'https://drive.google.com/file/d/1FKZdKugak4MER0yv1Wy8bRDA_lbzCinI/view?usp=share_link',
        isHighlighted: true,
      },
 {
        label: 'Developed by MKAITS',
        href: 'https://www.mkaits.com',
        isHighlighted: true,
      },
    ],
  },

  
]
