import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'GS1',
    lpAddresses: {
      97: '0x62e0aef5E34c64043CDCD9B8826A97d01c4736a3',
      56: '0xe38E3a21A4c0887E1aB0c2fbc3CAA4c71d8aE170',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
{
    pid: 1,
    lpSymbol: 'GS1-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x45842d743b71e0326E1C92a195799c8EC47297b9',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
]

export default farms
