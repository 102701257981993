import React from 'react'
import { useTranslation } from 'contexts/Localization'
import { Text } from '@pancakeswap/uikit'

const GS1Warning = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text>{t('To trade GS1, you must:')} </Text>
      <Text>• {t('Click on the settings icon')}</Text>
      <Text mb="24px">• {t('Set your slippage tolerance to 5%+')}</Text>
     
    </>
  )
}

export default GS1Warning
